body {
  margin: 0 !important;
  background-color: #ffffff !important;
  word-wrap: break-word;
  font-family: 'Open Sans', sans-serif !important;
}

.appBar {
  background-color: #223b67 !important;
}

.page-container {
  position: absolute;
  min-height: 100vh;
  min-width: 100%;
  background-color: #f7f5f5;
}

.bgcolor-override {
  
}

.narrow-select {
  max-width: 100px !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-auto {
  cursor: auto !important;
}

@media print {

  .header-toolbar svg, button, img {
    display: none !important;
  }

  .detail svg {
    display: none !important;
  }

  .single-detail svg {
    display: none !important;
  }

  .filter-container {
    padding-top: 1% !important;
  }

  .day-container {
    text-shadow: none !important;
  }

  .header-project {
    text-shadow: none !important;
  }

  .costCenter-text {
    text-shadow: none !important;
  }

  .crew-container {
    display: none !important;
  }

  .nav-container {
    display: none !important;
  }

  .bottom-container-addButton {
    display: none !important;
  }

  .history-container {
    border: none !important;
  }

  .history-center {
    font-size: 0px !important;
  }

  .undo-button-container svg {
    display: none !important;
  }

  #filterContainer {
    display: none !important;
  }

  #driverPayloadSum {
    text-shadow: none !important;
  }

  #totalMixtureAmountSum {
    text-shadow: none !important;
  }

  #mixtureAmountSum {
    text-shadow: none !important;
  }

  .history-container {
    display: none !important;
  }

  .firstColumnContainer {
    display: none !important;
  }

  .pensum {
    box-shadow: 0 0 2px rgba(9,30,66) !important;
  }

  .page-main {
    overflow: visible !important;
  }
  
  .planning-table {
    overflow: visible !important;
  }

  .header-container {
    display: none !important;
  }

  /*.specialday {
    display: none !important;
  }*/

}