.mixingPlant .MuiInput-formControl {
    margin-top: 0px !important;
    font-size: 1em !important;
    width: auto !important;
}

.MuiTypography-body1 {
    font-size: 0.9em !important;
}

.MuiTableCell-root {
    padding: 1px !important;
}

.expandedPopup {
    width: 280px !important;
}

.rowC {
    overflow: visible !important;
    display: flex;
    flex-direction: row;
}

.mixingPlantLabel {
    margin-top: 12px !important;
    padding: 5px !important;
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    width: auto !important;
}

.MTableToolbar-spacer-177 {
    flex: 0 !important;
}

.inputH {
    display: 'none' !important;
}

.MuiToolbar-root {
    flex-wrap: wrap !important;
}

.MuiTableCell-root {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.transformed-mixing-plant-cluster-label {
    width: 200px;
    margin-top: -30px !important;
    font-size: 14px !important;
}

.create-popup {
    width: 500px;
    min-height: 120px;
    margin: 0 auto;
    margin-top: calc(100vh - 100vh / 100 * 55);
    padding-top: 8px;
    padding-bottom: 18px;
    background-color: rgb(253, 253, 253);
    outline: none;
}

.popup-title {
    padding: 10px !important;
    padding-bottom: 40px;
    font-size: 18px !important;
}